@import "../styles/variables.scss";

.faq_body {
  padding: 100px 200px;
}

@media (max-width: 1500px) {
  .faq_body {
    padding: 100px 200px;
  }
}

@media (max-width: 1000px) {
  .faq_body {
    padding: 100px 100px;
  }
}

@media (max-width: 700px) {
  .faq_body {
    padding: 100px 30px;
  }
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;

  .contact-info__title {
    text-align: center;
    color: $color-brand-accent;
    font-weight: $text-bold;
    margin-bottom: 32px;
  }

  .contact-info__button {
    font-weight: $text-bold;

    a {
      display: flex;
      flex-direction: column;
      text-decoration: none;
      padding: 32px;
    }

    .contact-us {
      font-weight: $text-bold;
      color: $color-brand-dim;
    }

    .email {
      color: black;
    }
  }
}

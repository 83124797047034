.base-text-input {
  width: 100%;
  color: black;

  .label {
    text-align: left;
  }

  .input-field {
    width: 100%;
    height: calc(32px + 8px);
    padding-left: .6rem;
    background-color: #f6f6f6;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid black;
    box-shadow: none;
  }
}

@import "../styles/variables.scss";

.base-table {
  background-color: lightgrey;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  word-break: keep-all;

  .base-table__row, .base-table__header-cell, .base-table__data-cell {
    border: 2px solid white;
  }

  .base-table__header {
    background-color: $color-brand;
  }

  .base-table__row {
    //======================
    // Alignment Modifiers
    //======================
    &.left {
      text-align: left;
    }

    &.right {
      text-align: right;
    }

    &.center {
      text-align: center;
    }

    //======================
    // Row Height
    //======================
    height: 78px;

    //========================
    // 색상이 even 하지 않아요
    //========================
    &:nth-child(even) {
      .base-table__data-cell {
        background-color: $color-brand-pale;
      }
    }

    &:nth-child(odd) {
      .base-table__data-cell {
        background-color: $color-brand-light;
      }
    }
  }
}

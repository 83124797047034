#container {
  width: 100%;
  height: 100%;
  padding: 0;
  background: #ffffff;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.intro-section {
  align-items: center;
  justify-content: center;
  align-content: center;

  font-style: normal;
  text-align: center;

  /*background-image: url("../assets/HomeSection/gradation_blue.png");*/
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;

  box-sizing: border-box;
  font-weight: 900;
  font-size: 60px;
  line-height: 66px;

  display: flex;
  flex-direction: column;

  // padding-top: min(7vw, 100px);
  // padding-bottom: min(7vw, 100px);

  height: calc(100vh - 79px);
  padding-bottom: 15vh;
  
  h1 {
    font-style: normal;
    font-weight: 900;
    font-size: min(3vw, 40px);
    text-align: center;
    white-space: pre;
    color: #00bef2;
    @media (max-width: 810px) {
      font-size: min(5vw, 40px);
    }
  }
}

.intro-introduce {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-evenly;
  gap: min(10vw, 100px);
  padding: min(7vw, 100px);
}

.sub-slogan {
  font-weight: 600;
  font-size: 32px;
  line-height: 150%;
  text-align: center;
  white-space: pre-wrap;
  font-style: normal;
  color: #5c5c5c;
  margin: 30px 0px 30px 0px;
}

.get-blurtle-button {
  padding: min(2.5vw, 32px) min(10vw, 150px);
  border-color: transparent;
  background: #00bbee;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  margin-top: min(3vw, 30px);
  margin-bottom: min(3vw, 30px);

  font-weight: bold;
  font-size: min(2.5vw, 30px);
  line-height: 22px;
  letter-spacing: 0.005em;
  color: #ffffff;
  @media (max-width: 810px) {
    padding: min(2.5vw, 32px) min(10vw, 150px);
    font-size: min(3.5vw, 40px);
  }

  color: #ffffff !important;
  width: auto !important;
}

.ApplicationMainImage {
  border-radius: 8px;
}

.ApplicationMainImage::before {
  content: "";
  position: absolute;
  z-index: -1;
  filter: blur(32px);
  border-radius: 8px;
}

.ApplicationMainImage img {
  width: 1500px;
  max-width: 100%;
}

.grid-container {
  display: grid;
  row-gap: 10px;
  column-gap: 20px;
  padding: 0px 300px 0px 300px;
  grid-template-columns: auto auto auto auto auto;
  margin: 10px 0px 10px 0px;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
}

.catalog-section {
  margin: 50px 0px 50px 0px;
}

.catalog-introduce {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-evenly;
  gap: min(10vw, 100px);
  padding: min(7vw, 100px);
}

.catalog-introduce > h1 {
  font-style: normal;
  line-height: 150%;
  letter-spacing: 0.0025em;
  color: #007596;
  font-weight: 400;
  font-size: 50px;
  text-align: center;
}

.catalog-introduce > h1 strong {
  font-weight: 900;
}

.catalog-introduce .catalog-section-description p {
  /*
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  color: rgba(39, 39, 42, 0.75);
*/
  line-height: 135%;
  letter-spacing: -0.03em;
  white-space: pre-wrap;
}

.catalog-introduce .image-with-background img {
  width: 1000px;
  height: 800px;
  max-width: 100%;
}

.catalog-howtouse {
  background: url(../assets/HomeSection/HowToUse_bg.jpg);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 150px;

  padding: 200px 0px;
}

.catalog-howtouse-title > h1 {
  font-style: normal;
  line-height: 150%;
  letter-spacing: 0.0025em;
  color: #00bef2;
  font-weight: 900;
  font-size: 50px;
  text-align: center;
}

.catalog-howtouse-title > p {
  font-weight: 500;
  font-size: 22px;
  font-style: normal;
  line-height: 130%;
  letter-spacing: 0.0025em;

  color: black;
  text-align: center;
}

.catalog-section1 {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.25);
  margin: 300px 0px 300px 0px;
  padding: 200px 30px 200px 0px;
}

.catalog-section2 {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 200px;

  margin: 300px 0px 300px 0px;
  padding: 200px 30px 200px 0px;
}

.catalog-section2-title > h1 {
  font-style: normal;
  line-height: 130%;
  letter-spacing: 0.0025em;

  color: black;
  font-weight: 900;
  font-size: 44px;
  text-align: center;
}

.catalog-section2-title > p {
  font-weight: 500;
  font-size: 22px;
  font-style: normal;
  line-height: 130%;
  letter-spacing: 0.0025em;

  color: black;
  text-align: center;
}

.catalog-section3 {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 200px;
  margin: 300px 0px 300px 0px;
  padding: 200px 30px 300px 0px;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.25);
}

.catalog-section3-title > h1 {
  font-style: normal;
  line-height: 150%;
  letter-spacing: 0.0025em;

  color: black;
  font-weight: 900;
  font-size: 44px;
  text-align: center;
}

.catalog-section3-title > p {
  font-weight: 500;
  font-size: 22px;
  font-style: normal;
  line-height: 150%;
  letter-spacing: 0.0025em;

  color: black;
  text-align: center;
}

.catalog-section4 {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 200px;
  margin: 300px 0px 300px 0px;
  padding: 200px 30px 300px 0px;
}

.catalog-section4-title > h1 {
  font-style: normal;
  line-height: 150%;
  letter-spacing: 0.0025em;

  color: black;
  font-weight: 900;
  font-size: 44px;
  text-align: center;
}

.catalog-section4-title > p {
  font-weight: 500;
  font-size: 22px;
  font-style: normal;
  line-height: 150%;
  letter-spacing: 0.0025em;

  color: black;
  text-align: center;
}

.catalog-section-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 100px;
  /*margin: 10px 40px 10px 40px;*/
}

.catalog-section-col {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 300px 0px 300px 0px;
}

.catalog-section-head {
  font-style: normal;
  line-height: 130%;
  letter-spacing: 0.0025em;

  color: #000000;
  text-align: center;
}

.catalog-section-head > h1 {
  font-weight: 900;
  font-size: 44px;
}

.catalog-section-head > p {
  font-weight: 500;
  font-size: 22px;
}

.catalog-section-description {
  font-style: normal;
  display: grid;
  gap: 10px;

  .icon {
    margin-left: 16px;
  }
}

.catalog-section-description > h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 130%;
  letter-spacing: 0.0025em;
  white-space: pre-wrap;

  color: #00bef2;
}

/*
.catalog-section-description > p {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 135%;

  letter-spacing: -0.03em;

  color: rgba(39, 39, 42, 0.75);

}
*/

.get-blurtle-button:disabled {
  background-color: gray; /* 비활성화 상태에서의 배경색 */
  cursor: not-allowed; /* 커서 스타일 */
  opacity: 0.5; /* 투명도 조정 */
}

.app-image-with-description {
  display: flex;
  flex-direction: row;

  .image {
    width: 40%;
    height: auto;
  }

  .description {
    width: 60%;
    text-align: left;
  }

  &.app-image-with-description--gravity-right {
    flex-direction: row-reverse;

    .description {
      text-align: left;
    }
  }

  @media (max-width: 810px) {
    flex-direction: column;

    &.app-image-with-description {
      flex-direction: column;
    }

    .image,
    .description {
      width: 100%;
    }
  }
}

.app-description {
  .text-wrapper {
    .text-title {
      font-size: 1.2rem;
    }

    .text-description {
      font-size: 1.1rem;
    }
  }
}

.text-title {
  font-size: min(2vw, 29px);
  color: #00bef2;
  strong {
    font-weight: 900;
    color: #47aee6;
  }
  margin: 20px;
  @media (max-width: 810px) {
    margin: min(5vw, 90px);
    font-size: min(4.5vw, 45px);
    text-align: lelf;
  }
}

.text-description {
  font-size: min(1.3vw, 18px);
  font-weight: 100;
  color: #5c5c5c;
  margin: 20px;

  strong {
    font-weight: 900;
  }
  white-space: break-spaces;
  @media (max-width: 810px) {
    margin-top: min(5vw, 90px);
    margin-bottom: 0px;
    font-size: min(3.5vw, 40px);
    text-align: left;
  }
}

img {
  object-fit: contain;
}

.icon {
  width: min(10vw, 50px);
}

.scroll-mouse {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  
  a {
    padding-top: 60px;
    span {
      position: absolute;
      top: 0;
      left: 50%;
      width: 30px;
      height: 50px;
      margin-left: -15px;
      border: 2px solid #555;
      border-radius: 50px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      
      &::before {
        position: relative;
        top: 0;
        content: '';
        width: 6px;
        height: 6px;
        background-color: #555;
        border-radius: 100%;
        -webkit-animation: sdb 2s infinite;
        animation: sdb 2s infinite;
        box-sizing: border-box;
      }
    }
  }
  
  @-webkit-keyframes sdb {
    0% {
      -webkit-transform: translate(0, 0);
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      -webkit-transform: translate(0, 20px);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  
  @keyframes sdb {
    0% {
      transform: translate(0, 0);
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      transform: translate(0, 20px);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  
  h5 {
    margin-top: 60px;
    color: #555;
    font-size: 1rem;
  }

  /* 반응형 디자인 적용 */
  @media (max-width: 768px) {
    bottom: 10%;
    a {
      span {
        width: calc(30px * 0.75);
        height: calc(50px * 0.75);
        margin-left: calc(-15px * 0.75);
        border-width: calc(2px * 0.75);
        
        &::before {
          width: calc(6px * 0.75);
          height: calc(6px * 0.75);
          -webkit-animation: sdb-small 2s infinite;
          animation: sdb-small 2s infinite;
        }
      }
    }
    h5 {
      font-size: 0.75rem;
      margin-top: 50px;
    }
  }

  @-webkit-keyframes sdb-small {
    0% {
      -webkit-transform: translate(0, 0);
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      -webkit-transform: translate(0, 15px);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes sdb-small {
    0% {
      transform: translate(0, 0);
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      transform: translate(0, 15px);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes sdb-smaller {
    0% {
      -webkit-transform: translate(0, 0);
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      -webkit-transform: translate(0, 10px);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes sdb-smaller {
    0% {
      transform: translate(0, 0);
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      transform: translate(0, 10px);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}



@import '../../styles/variables.scss';

.blurtle-h1 {
  color: $color-brand-accent;
  font-weight: $text-bold;
}

.blurtle-h2 {
  color: $color-brand-accent;
}

.base-modal {
  .base-modal__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #3b3b3b50;
    z-index: 9998;
  }

  .base-modal__body {
    position: fixed;
    width: 550px;
    height: 360px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50px;
    background-color: white;
    z-index: 9999;
  }
}

.policy-text .div > h1 {
    font-size: 32px;
}

.policy-text > h1 {
    font-size: 28px;
}

.policy-text > h2 {
    font-size: 24px;
}

.policy-text > h3 {
    font-size: 20px;
}

.policy-text > h4 {
    font-size: 16px;
}

.policy-text > h5 {
    font-size: 12px;
}
@import "../styles/variables.scss";

.base-button {
  border: none;
  border-radius: 50px;
  min-height: min(60px, 10vw);
  background-color: $color-brand;
  color: black;
  width: 80%;

  &:disabled {
    background-color: #9c9c9c;
    cursor: not-allowed;
  }
}

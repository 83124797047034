.copyright {
  display: flex;
  align-items: center;
  padding-top: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.0025em;
  color: #ffffff;
  font-size: 18px;
  line-height: 128%;
  /* or 26px */
}

.footer {
  background: #232323;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  font-style: normal;
  font-size: 24px;
  font-weight: 400;
  line-height: 128%;
  padding: 50px 0;
  margin-top: 50px;
  box-sizing: border-box;
}

.footer-inner {
  width: 1400px;
  padding: 0 48px;
  box-sizing: border-box;
}

.terms-and-policy-with-sns {
  display: flex;
  justify-content: flex-start;
  /* width: 1920px; */
  font-size: 15px;
  justify-content: space-between;
}

.terms-and-policy-with-sns .socialBox {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.first-column-row {
  font-style: normal;
  color: #000000;
  font-size: 20px;
  margin: 0px 10px 0 0px;
}

.first-column-row > h5 {
  color: #000000;
  display: inline;
  font-weight: 600;
  line-height: 128%;
  margin: 0px 10px 0px 0px;
}

.first-column-row > p {
  color: #282727;
  display: inline-grid;
  margin: 0px 0px 0px 10px;
}

.second-column {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.company-name {
  font-style: normal;
  font-size: 24px;
  font-weight: 500;
  line-height: 128%;
  color: #70dcfe;
}

.field {
  font-size: 24px;
  color: #ffffff;
}

.value {
  font-style: normal;
  font-size: 24px;
  font-weight: 500;
  line-height: 128%;
  color: #b4b4b4;
}

.verticalLine {
  border-left: 1px solid black;
  height: 30px;
}

.social-icon {
  width: 2rem;
  height: 2rem;
}

.information {
  font-size: min(1.5vw, 15px);
}

@media all and (max-width: 630px) {
  .company-name {
    display: grid;
    font-size: max(4vw, 10px);
  }

  .terms-and-policy-with-sns {
    display: flex;
    justify-content: flex-start;
    /* width: 1920px; */
    font-size: max(2vw, 7px);
    justify-content: space-between;
  }

  .terms-and-policy-with-sns .socialBox {
    float: right;
    justify-content: space-between;
  }

  .social-icon {
    width: 6.2vw !important;
    height: 6.2vw !important;
  }
  .social-svg {
    width: 6vw !important;
    height: 6vw !important;
  }

  .copyright {
    font-size: 2.5vw;
  }
}

#top-navbar-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

#top-navbar-wrap nav.navbar.navbar-expand.navbar-light {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1400px;
}

#top-navbar-wrap .default-nav-link {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  /* identical to box height, or 24px */

  letter-spacing: 0.005em;
  color: #191619;
}

#top-navbar-wrap .default-nav-link:hover {
  color: #00bbee;
}

#top-navbar-wrap .default-nav-link.active {
  color: #00bbee;
  background: #ffffff;
}

#top-navbar-wrap nav.navbar-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

#top-navbar-wrap .container {
  width: 1920px;
}

#top-navbar-wrap div.nav-item {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  /* identical to box height, or 24px */

  letter-spacing: 0.005em;
}

/* Nav.Link active style. (styled component에서 안 먹어서 따로..*/
#top-navbar-wrap .navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: #ffffff;
  background-color: #00bbee;
  border-radius: 10px;
}

#top-navbar-wrap .nav-pills .nav-link.active {
  color: #ffffff;
  background-color: #00bbee;
  border-radius: 10px;
}
/**/

#top-navbar-wrap a.navbar-brand {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 150%;
  color: #1bb3da;

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
}

/* Affect all child element */
#top-navbar-wrap a.navbar-brand > * {
  color: #1f93c6;
}

#top-navbar-wrap a.navbar-brand:hover {
  color: #1f93c6;
}

#top-navbar-wrap div.navbar-nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  gap: 28px;
  margin-right: 20px;
  --bs-navbar-hover-color: #1f93c6; /*#B7CC15*/
  --bs-nav-link-font-weight: 400;
}

/* Language selection 'a' link style before click */
#top-navbar-wrap div.nav-item.dropdown > .nav-link {
  background: rgba(27, 179, 218, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: #00acdb;
}

/* Language selection style after click */
#top-navbar-wrap div.nav-item.show.dropdown > .nav-link {
  background: rgba(27, 179, 218, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

/* Mobile hamburger menu. */
.hamburger-menu-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 13px;
}

.hamburger-menu-button > .bun {
  width: 30px;
  height: 4px;
  background-color: #1bb3da;
}

.hamburger-menu-button > .cross-bar {
  position: absolute;
  width: 30px;
  height: 4px;
  background-color: #1bb3da;
}

.hamburger-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #56cbea;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 60px;
  z-index: 999;
}

.hamburger-menu > .menu-item {
  text-decoration: none;
  color: white;
  border-bottom: 5px solid #47aee6;
  font-size: 20px;
  font-weight: 500;
  padding: 5px;
  padding-left: 30px;
  padding-top: 20px;
  width: 85%;
}

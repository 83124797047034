.containerBox {
  width: 100%;
  height: 100%;

  display: "flex";
  flex-wrap: "wrap";
  justify-content: "space-between";
  gap: 20px;
  padding: 100px;
  // margin: 100px 0px 0px 0px;
}

.license-modal {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 40px;
  align-content: space-around;
}

@media (max-width: 768px) {
  .containerBox {
    gap: 20px;
    padding: 10px;
    padding-bottom: 100px;
    padding-top: 100px;
  }
}

.cell-button {
  width: 80%;
}

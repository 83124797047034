.terms-section {
  margin-top: 48px;
  color: black;
  width: 100%;

  .title {
    border-bottom: 1px solid black;
  }

  .item {
    text-align: left;
  }
}
.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* gap: 200px; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
    color: #1F93C6;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.contentWrapper {
  flex: 1;
  display: flex;
  justify-content: center;
}

.contentInner {
  max-width: 1280px;
  width: 100%;
}
.orderInfo {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 80%;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;

  .input-row {
    display: flex;
    width: 100%;

    .name-column {
      display: flex;
      flex-direction: row-reverse;
      gap: 16px;
      width: 100%;

      &.name-column--last-first {
        flex-direction: row;
      }
    }
  }
}

.inputBox {
  display: inline-block;
  width: 90%;
  padding: 10px 20px;
  text-align: left;

  input {
    border: 1px solid transparent;
    border-radius: 5px;
    font-size: 16px;
    line-height: 32px;
  }

  .email {
    width: 100%;
    outline-style: solid;
    outline-color: rgba(170, 170, 170, 0.1875);
    box-shadow: 8px 8px 24px rgba(0, 0, 0, 0.25);
  }

  .nameBox {
    display: inline-block;
    width: 50%;
  }

  .nameBox input {
    width: 70%;
    outline-style: solid;
    outline-color: rgba(170, 170, 170, 0.1875);
    box-shadow: 8px 8px 24px rgba(0, 0, 0, 0.25);
  }

  .nameBox label {
    padding-left: 10px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
  }
}

@media (min-width: 768px) {
  .orderInfo {
    flex-direction: row;
  }
  .inputBox {
    width: 50%;
  }
}

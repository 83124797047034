$collapsed-height: 65px;

.blurtle-accordion {
  min-height: calc($collapsed-height + 1px);
  border-bottom: 1px solid black;
  overflow: hidden;
  box-sizing: border-box;

  &.blurtle-accordion--init {
    max-height: $collapsed-height;
  }

  .summary-wrapper {
    display: flex;
    align-items: center;
    min-height: $collapsed-height;
    cursor: pointer;
  }

  .details-wrapper {
    overflow: hidden;
    transition: height .3s ease-out, opacity .3s linear;
  }
}

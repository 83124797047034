@import '../styles/variables.scss';

.blurtle-progress-bar {
  position: relative;
  width: 300px;
  height: 32px;
  background-color: grey;
  border-radius: 8px;
  overflow: hidden;

  .value {
    height: 100%;
    background-color: $color-brand-accent;
  }

  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
}
